<template>
  <div>    
    <b-card class="card-custom">
      <b-row>
        <b-col md="6">
          <b-form-group label="Utilisateurs">
            <Input type="select-model" v-model="filters.user_id" :options="$api.params.User"/>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Date, du">
            <Input type="date" v-model="filters.start"/>
          </b-form-group>            
        </b-col>
        <b-col md="3">
          <b-form-group label="au">
            <Input type="date" v-model="filters.end"/>
          </b-form-group>            
        </b-col>
        <b-col md="12" class="text-center">
          <b-button variant="primary" @click="init()">Rechercher <i v-if="load" class="fas fa-spin fa-spinner"></i></b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="card-custom mt-3">
      <b-table striped hover :items="stats" :fields="fields" foot-clone>
        <template #cell(Ratio)="data">
          {{ data.value }}%
        </template>
        <template #foot()="data">
          <span v-if="data.column === 'Nombre de leads'">{{ nb_leads }}</span>
          <span v-else>{{ data.label }}</span>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import Input from '@/components/InputForm';
export default {
  name: "ProspectStats",
  components: {
    Input
  },
  watch:{    
  },
  data(){ 
    return {
      load: false,
      filters:{
        start: this.$api.moment().startOf('month').startOf('day').format('YYYY-MM-DD'),
        end: this.$api.moment().format("YYYY-MM-DD"),
        user_id:[]
      },
      fields:[
        { "key":"id", sortable:true },
        { "key":"Commercial", sortable: true },
        { "key":"Nombre de leads", sortable: true },
        { "key":"Ratio", sortable: true },
      ],
      stats:[]
    }
  },
  computed:{    
    nb_leads(){
      var total = 0;
      this.stats.forEach(e => {
        total += e['Nombre de leads'];
      });
      return total;
    }
  },
  methods: {
    init(){
      if(this.load){
        return false
      }
      this.load = true;
      let tmp = { ...this.filters};
      if(tmp.user_id.length === 0){
        tmp.user_id = this.$api.params.User.map(e => e.id);
      }
      this.$api.ajax('/lead/stats', tmp, res => {
        if(res.status){
          this.stats = res.data;
        }
        this.load = false
      })
    }
  },
  beforeMount() {
  },
  mounted() {    
    this.init();  
  }
}
</script>
<style>
@media print {
  .menu-desktop, #menu-mobile, #header{
    display: none !important;
  }            
}
</style>
